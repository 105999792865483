.basic-setting-page {
    margin: 24px;
    padding: 80px 120px;
    background-color: white;
}

.basic-setting-page h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.basic-setting-page h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.basic-setting-page .submit-button, .submit-button:focus, .submit-button:hover {
    background-color:#C62828 !important;
    border: #C62828;

}