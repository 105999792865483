.lead-agent-main {
    padding: 20px
}

.lead-agent-main h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.agent-lead-header {
    background-color: white;
    padding: 24px 32px;
    justify-content: space-between;
    margin: 0px !important
}

.agent-lead-table {
    background-color: white;
    padding: 24px 32px;
    margin-top: 28px
}

.agent-lead-table span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.add-new-button {
    background: #C62828;
    color: white !important;
    border-radius: 4px;
    border-color: #C62828
}

.add-new-button:hover {
    background: #C62828;
    color: white !important;
    border-radius: 4px;
    border-color: #C62828
}

.add-new-button:focus {
    background: #C62828;
    color: white !important;
    border-radius: 4px;
    border-color: #C62828
}

.agent-lead-table button span {
    color: black
}

.add-new-button span {
    color: white !important;
}

.agent-lead-table h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0px
}

.agent-Table-action button {
    color: #C62828
}

/* Detail View */

.basic-info-row p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);

}

.basic-info-row span {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}