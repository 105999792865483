.header {
    background-color: white;
    padding: 16px 32px
}

h1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
}

.filter-form {
    background-color: white;
    padding: 48px 32px !important;
    margin-bottom: 20px;
    border-radius: 2px;
}

.main-table {
    background-color: white;
    padding: 48px 32px;
    margin-bottom: 20px;
    border-radius: 2px;
}

.search-button {
    background: #C62828;
    border-radius: 2px;
    color: white;
    border: 1px solid #C62828 ;
}