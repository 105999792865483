.facebook-layout {
    margin: 24px;
    background-color: white;
    padding: 28px 32px
}

h1 {
font-family: PingFang SC;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: rgba(0, 0, 0, 0.85);
}

h2 {
font-family: PingFang SC;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
color: rgba(0, 0, 0, 0.65);
margin:0
}

h3 {
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 22px;
color: rgba(0, 0, 0, 0.25);
margin:0
}

h4 {
font-family: Helvetica Neue;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 22px;
color: rgba(0, 0, 0, 0.45);
margin:0
}

.ant-dropdown-link, .ant-dropdown-link:hover {
    color: #C62828
}

.export-button {
    color: #C62828
}