.finance-team {
    background-color: white;
    margin: 32px;
    padding: 16px 24px
}

h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
}

h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.add-button {
    width: 104px;
    height: 32px;
    background: #C62828;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    color: white
}