.main-content {
    margin: 24px;
    background-color: white;
    padding: 28px 32px
}

.title-row {
    padding-top: 8px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;
}

.page-header {
    justify-content: space-between;
    padding: 16px 24px;
    background: white
}

.page-header h1 {
    font-size: 20px;
    margin: 0px
}

.page-header span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400
}

.page-header button span {
    color: white
}

.page-header button {
    background-color: #C62828;
    border-color: #C62828;
    border-radius: 2px;
}

.page-header button:hover {
    background-color: #9E2020;
    border-color: #9E2020;
}

.page-header button:focus {
    background-color: #9E2020;
    border-color: #9E2020;
}

.main-content h1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
}


.ads-list p {
    margin: 0;
}

.ads-list {
    padding-top: 36px;
    background-color: white;
}

.ads-list span {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.45);
}

.ads-listing-space-first-box span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
    gap: 4px;
}

.ads-listing-space span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    gap: 4px
}

.action-column button span {
    color: #C62828;
    cursor: pointer;
}

.action-column button {
    padding: 4px
}

.create-modal h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    background-color: white;
    color: rgba(0, 0, 0, 0.65);
}

.submit-button {
    background-color: #C62828;
    border-color: #C62828;
    border-radius: 4px;
}

.submit-button:hover {
    background-color: #9E2020;
    border-color: #9E2020;
}

.submit-button:focus {
    background-color: #9E2020;
    border-color: #9E2020;
}