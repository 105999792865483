.main-layout {
    background-color: #FAFAFA;
    padding-top: 10%;
}

.main-space {
    width: 400px;
    margin: 0 auto;
    height: 100vh;
    background-color: #FAFAFA;
}

.forgot-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #C62828;
}

.remember-me-checkbox {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
}

.login-input .ant-input .ant-input-lg  .login-input:hover, .login-input:focus, .login-input::content{
    background-color: #FAFAFA !important;
}

.login-input {
    border-radius: 4px;
}