.promo-code-layout {
    padding: 28px 32px;
    background-color: white;
    margin: 24px;
}

.promo-code-layout h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
}

.promo-code-layout span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.promo-code-header {
    padding: 16px 24px;
    background: white
}

.promo-code-header h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
}

.add-promo-main-form {
    margin: 24px;
    padding: 80px;
    background-color: white;
}

.promo-code-submit-button, .promo-code-submit-button:hover, .promo-code-submit-button:focus {
    
    background: #C62828;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    border-color: #C62828
}

.promo-code-submit-button span, .add-new-button span {
    color: rgb(248, 239, 239) !important;
}