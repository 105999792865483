.lead-agent-main {
    padding: 20px
}

.lead-agent-main h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.agent-lead-header {
    background-color: white;
    padding: 24px 32px;
    justify-content: space-between;
    margin: 0px !important
}

.agent-lead-table {
    background-color: white;
    padding: 24px 32px;
    margin-top: 28px
}

.agent-lead-table span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.add-new-button {
    background: #C62828;
    color: white !important;
    border-radius: 4px;
    border-color: #C62828
}

.add-new-button:hover {
    background: #C62828;
    color: white !important;
    border-radius: 4px;
    border-color: #C62828
}

.add-new-button:focus {
    background: #C62828;
    color: white !important;
    border-radius: 4px;
    border-color: #C62828
}

.agent-lead-table button span {
    color: black
}

.add-new-button span {
    color: white !important;
}

.agent-lead-table h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0px
}

.Table-action button span {
    color: #C62828
}

/* Detail View */

.basic-info-row p {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    
    
    color: rgba(0, 0, 0, 0.85);

}

.basic-info-row span {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    
    
    color: rgba(0, 0, 0, 0.65);
}

.table_className {
    margin-top:80px
}

.table_className h2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    
    color: rgba(0, 0, 0, 0.85);
}

.table_className span {
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */


/* Character/Secondary .45 */

color: rgba(0, 0, 0, 0.45);
    
}

h2 {
    font-family: PingFang SC;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


color: rgba(0, 0, 0, 0.85);
}

h1 {
font-family: Helvetica Neue;
font-style: normal;
font-weight: normal;
font-size: 30px;
line-height: 38px;
color: rgba(0, 0, 0, 0.85);
margin:0
}

h3 {
    font-family: PingFang SC;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
color: rgba(0, 0, 0, 0.85);
margin:0
}

p{
    margin:0
}

.car_dealer_p {
    font-family: PingFang SC;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */


color: rgba(0, 0, 0, 0.45);
}